import React from "react";
import Header from "../components/header/header.js";
import InfoSlider from "../components/infoSlider/infoSlider.js";
import CheckInOnlineIcon from "../assets/img/svg/checkin-online.svg";

const INFOSLIDES = [
  {
    body: `Invio link per effettuare il checkIN online automatico al momento della prenotazione`,
  },
  {
    body: `Notifica alla struttura tramite email ogni volta che viene aggiornato un documento del cliente`,
  },
  {
    body: `Possibilità di copiare il link da parte del cliente per inviarlo ad altri eventuali ospiti della stessa prenotazione`,
  },
  {
    body: `Possibilità di copiare il link all'interno del back office di Book@Me anche per prenotazioni ricevute dai portali e inserite dal PMS del cliente`,
  },
];

const CheckInOnline = () => {
  return (
    <>
      <Header
        title="CheckIN Online"
        description={`Ti consente di gestire in modo rapido ed automatico tutti i portali di distribuzione online da un solo back-office.`}
        categoryIcon={{
          icon: CheckInOnlineIcon,
          alt: "CheckIN Online Banner",
        }}
        metaDescription="Ti consente di gestire in modo rapido ed automatico tutti i portali di distribuzione online da un solo back-office."
      />
      <div className="container">
        <div className="columns">
          <div className="column is-7">
            <span className="green bold">
              In maniera autonoma, i tuoi utenti, potranno inviare i documenti e
              tutte le informazioni per l’acquisto del soggiorno, facilitando il
              lavoro del receptionist in fase di registrazione.
            </span>
            <p className="description">
              <br />
              Nel calendario tariffario sono visualizzati tutti i dati relativi
              alla percentuale di occupazione, il RevPAR (Revenue per available
              room) e l'average rate (prezzo medio per camera venduta). Tutti
              questi strumenti faciliteranno la scelta di una tariffa piuttosto
              che un'altra con molta semplicità.
            </p>
          </div>
        </div>
      </div>
      <div className="section" />
      <InfoSlider
        staticTitle={"Principali funzionalità implementate: checkIN online"}
        infoslides={INFOSLIDES}
        image={"bam-phone"}
        alt={"Book@Me Desktop View"}
      />
      <div className="section" />
    </>
  );
};

export default CheckInOnline;
